import React, { useMemo, useRef, useState, useEffect } from 'react'

import { Form, Select as AntSelect, Spin } from 'antd'
import debounce from 'lodash/debounce'

import { FILTER_PAGE, FILTER_SEARCH } from 'utils/api/filters'
import { SelectAsyncProps, SelectOption, SelectOptions } from 'utils/types/select'

import paging from 'services/paging'

import { Container } from '../select/styles'

type MyItem = Record<string, unknown>

// We accept { label: string; value: string } as final
type KeyMapper = Record<string, string | ((obj: MyItem) => string | boolean)>

function formatOptions (items: MyItem[], keys: KeyMapper): SelectOptions {
    return items.map(item => {
        const opt = Object.keys(keys).reduce((acc, key) => {
            const rule = keys[key]
            const val = typeof rule === 'string' ? item[rule] : rule(item)

            if (key === 'value') {
                acc.value = String(val ?? '')
            } else if (key === 'label') {
                acc.label = String(val ?? '')
            } else {
                acc[key] = val as string | boolean
            }
            return acc
        }, {} as Partial<SelectOption>)

        return opt as SelectOption
    })
}

function SelectAsync ({
    label,
    name,
    data,
    multiple,
    filterOptions = () => true,
    pagination = true,
    onChange,
    disabled,
    style,
    defaultValue,
    labelInValue,
    ...props
}: SelectAsyncProps) {
    const [options, setOptions] = useState<SelectOptions>([])
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const fetchIdRef = useRef(0)

    function fetchData (query?: string, resetPage?: boolean) {
        if (disabled) return

        fetchIdRef.current += 1
        const fetchId = fetchIdRef.current
        setLoading(true)

        paging(data.endpoint, {
            pagination,
            ...data.params,
            [FILTER_PAGE]: resetPage ? 1 : page,
            [FILTER_SEARCH]: query || undefined
        })
            .then(response => {
                // cast items
                const items = response.items as MyItem[]
                if (fetchId !== fetchIdRef.current) return

                const newOpts = formatOptions(items, data.keys as KeyMapper)
                setOptions(prev => (resetPage ? newOpts : [...prev, ...newOpts]))
                if (pagination) {
                    setPage(resetPage ? 2 : page + 1)
                }
            })
            .finally(() => setLoading(false))
    }

    const debounceFetcher = useMemo(() => {
        return debounce((val: string) => {
            fetchData(val, true)
        }, 400)
    }, [data, disabled, pagination])

    useEffect(() => {
        fetchData(undefined, true)
    }, [])

    const handlePopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (!pagination) return
        const target = e.currentTarget
        if (!loading && target.scrollTop + target.offsetHeight + 100 >= target.scrollHeight) {
            fetchData()
        }
    }

    const handleSearch = (val: string) => {
        debounceFetcher(val)
    }

    return (
        <Container>
            <Form.Item label={label} name={name} {...props}>
                <AntSelect
                    mode={multiple ? 'multiple' : undefined}
                    disabled={disabled}
                    loading={loading}
                    showSearch
                    style={style}
                    labelInValue={labelInValue}
                    placeholder="Select..."
                    defaultValue={defaultValue}
                    onChange={onChange}
                    filterOption={false}
                    onSearch={handleSearch}
                    onPopupScroll={pagination ? handlePopupScroll : undefined}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                >
                    {options
                        .filter(opt => filterOptions(opt))
                        .map(opt => (
                            <AntSelect.Option
                                key={String(opt.value)}
                                value={opt.value as string}
                            >
                                {opt.label}
                            </AntSelect.Option>
                        ))}
                </AntSelect>
            </Form.Item>
        </Container>
    )
}

export default SelectAsync
