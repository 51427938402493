import styled from '@emotion/styled'

import { Upload, Tag } from 'antd'
import { UploadFileStatus } from 'antd/lib/upload/interface'

import { colors } from 'settings/theme'

export const FilesWrapper = styled.div`
  position: relative;
  
  .files-badge {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`

export const FileWrapper = styled.div<{status: UploadFileStatus}>`
  &:first-of-type {
    margin-top: 10px;
  }
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: #fafafa;
  color: ${({ status }) => colors[status === 'error' ? 'error' : 'black']};
  .close-icon {
    opacity: 0;
    color: ${colors.error};
  }
  &:hover {
    background-color: ${colors.error + 0x10};
    .close-icon {
      opacity: 1;
    }
  }
`

export const StyledUpload = styled(Upload)`
  .ant-upload-select {
    display: block;
    width: 100%;
  }
  
  .ant-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`

export const FileTag = styled(Tag)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 4px 8px;
  cursor: pointer;
  
  &:hover {
    background-color: ${colors.error + 0x10};
  }
`
