import React, { useEffect } from 'react'

import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import { InputProps } from '../input'
import { Container } from '../text/styles'

// Patterns for duration format validation (e.g., "10d 5h 30m 15s")
const REGEX_DURATION_FORMAT = /\d+[dhmsDHMS]/g
const REGEX_DURATION_VALID_UNITS = /[\ddhmsDHMS\s]/
const REGEX_DURATION_VALID = /^(\d+[dhmsDHMS](\s+\d+[dhmsDHMS])*)?$/

type DurationProps = InputProps & {
    onChange?: (value: number) => void
}

const Duration = ({
    name,
    label,
    rules,
    required,
    col,
    hidden,
    onChange,
    defaultValue,
    ...props
}: DurationProps): JSX.Element => {
    const { t } = useTranslation()
    const form = Form.useFormInstance()

    // Watch the field value using Form.useWatch
    const watchedValue = Form.useWatch(name, form)

    // Converts a formatted string (e.g., "2h30m") to seconds
    const convertToSeconds = (formattedValue?: string): number => {
        if (!formattedValue) return 0

        const matches: string[] = []
        formattedValue.replace(REGEX_DURATION_FORMAT, (match) => {
            matches.push(match.toLowerCase())
            return match
        })

        return matches.reduce((total, item) => {
            if (item.includes('s')) return total + parseInt(item, 10)
            if (item.includes('m')) return total + parseInt(item, 10) * 60
            if (item.includes('h')) return total + parseInt(item, 10) * 3600
            if (item.includes('d')) return total + parseInt(item, 10) * 86400
            return total
        }, 0)
    }

    // Converts seconds to readable format (e.g., "1d 5h 30m 10s")
    const convertToFormattedString = (seconds: number): string => {
        if (!seconds || isNaN(seconds)) return ''

        const days = Math.floor(seconds / 86400)
        let remaining = seconds % 86400

        const hours = Math.floor(remaining / 3600)
        remaining %= 3600

        const minutes = Math.floor(remaining / 60)
        remaining %= 60

        const parts: string[] = []
        if (days > 0) parts.push(`${days}d`)
        if (hours > 0) parts.push(`${hours}h`)
        if (minutes > 0) parts.push(`${minutes}m`)
        if (remaining > 0) parts.push(`${remaining}s`)

        return parts.join(' ')
    }

    // Initialize with default value
    useEffect(() => {
        if (!form || !name || !defaultValue) return

        if (typeof defaultValue === 'number' && defaultValue > 0) {
            const formattedValue = convertToFormattedString(defaultValue)
            form.setFieldsValue({ [name as string]: formattedValue })
            onChange?.(defaultValue)
        }
    }, [defaultValue, name, form, onChange])

    // React to watched value changes
    useEffect(() => {
        if (!form || !name || !watchedValue) return

        // If the value is a number, format it
        if (typeof watchedValue === 'number' && watchedValue > 0) {
            const formattedValue = convertToFormattedString(watchedValue)
            form.setFieldsValue({ [name as string]: formattedValue })
            onChange?.(watchedValue)
        } else if (typeof watchedValue === 'string' && REGEX_DURATION_VALID.test(watchedValue)) {
            // If it's a valid string format, convert to seconds for parent component
            const seconds = convertToSeconds(watchedValue)
            // Notify parent without modifying the field value
            onChange?.(seconds)

            // Important: Revalidate the field to clear any error state
            form.validateFields([name as string])
        }
    }, [watchedValue, name, form, onChange])

    // Handle allowed keys for better user experience
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Special keys allowed: backspace, delete, left/right arrows
        if ([8, 46, 37, 39].includes(e.keyCode)) return

        // Block unauthorized characters
        if (!REGEX_DURATION_VALID_UNITS.test(e.key)) {
            e.preventDefault()
        }
    }

    // Handle input change event to force validation
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        // Force field validation on every change to update the error state
        setTimeout(() => {
            form.validateFields([name as string])
        }, 0)
    }

    return (
        <Container>
            <Form.Item
                hidden={hidden}
                label={label}
                required={required}
                wrapperCol={col}
                name={name}
                validateTrigger={['onChange', 'onBlur']} // Important: validate on both events
                rules={[
                    ...(rules || []),
                    {
                        validator: async (_, value) => {
                            // Handle empty value
                            if (!value) {
                                if (required) return Promise.reject(t('form.input.errors.required'))
                                return Promise.resolve()
                            }

                            // Format validation - use original regex, not the cleaned one
                            if (!REGEX_DURATION_VALID.test(value)) {
                                return Promise.reject(t('form.input.duration.error'))
                            }

                            // Convert and notify parent
                            const durationInSeconds = convertToSeconds(value)
                            onChange?.(durationInSeconds)

                            return Promise.resolve()
                        }
                    }
                ]}
                help={t('form.input.duration.helper')}
                {...props}
            >
                <Input
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    placeholder={t('form.input.duration.helper')}
                />
            </Form.Item>
        </Container>
    )
}

export default Duration
