import styled from '@emotion/styled'

import { colors } from 'settings/theme'

export const Label = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
`

export const Container = styled.div<{ $width?: number | string; $height?: number | string }>`
  position: relative;
  background-color: #f6f6f6a4;
  border: 3px dashed #dcdcdc;
  border-radius: 10px;
  transition: all 400ms;
  box-sizing: border-box;
  padding: 1rem;
  width: ${({ $width }) => $width ? (typeof $width === 'number' ? `${$width}px` : $width) : '100%'};
  min-height: ${({ $height }) => $height ? (typeof $height === 'number' ? `${$height}px` : $height) : '200px'};
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${colors.primary + '10'};
    color: ${colors.primary};
    border: 3px dashed ${colors.primary};
  }

  /* Base layout for Ant Design components */
  .ant-upload-wrapper {
    width: 100%;
  }

  .ant-upload {
    width: 100% !important;
    background: transparent !important;
    border: none !important;
    display: flex !important;
    justify-content: center !important;
    margin: 0 !important;
  }

  /* Fixed centering for image list */
  .ant-upload-list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-list-picture-card {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  /* Image container styling */
  .ant-upload-list-picture-card-container {
    margin: 0 auto !important;
    float: none !important;
    width: auto !important;
    max-width: 100%;
  }
  
  /* Image container itself */
  .ant-upload-list-item {
    padding: 0 !important;
    border-radius: 4px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    aspect-ratio: ${({ $width, $height }) => ($width && $height) ? `${$width}/${$height}` : '16/9'};
    min-height: 150px;
  }
  
  /* Image element styling */
  .ant-upload-list-item-thumbnail {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .ant-upload-list-item-image {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover !important;
  }
  
  /* Upload button styling */
  .ant-upload-select-picture-card {
    border: none !important;
    background: transparent !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 240px;
  }
  
  /* Action buttons styling - preview and delete */
  .ant-upload-list-item-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 30px;
    

    .anticon-eye,
    .anticon-delete {
        color: ${colors.primary} !important;
        font-size: 125px;

        &:hover {
            color: ${colors.secondary || '#666'} !important;
        }
    }
  }
`
