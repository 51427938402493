import React, { useState, useEffect, useCallback } from 'react'

import { UploadOutlined } from '@ant-design/icons'
import { MdClose } from '@react-icons/all-files/md/MdClose'
import { Upload as AntdUpload, Form, Input, message, UploadProps } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'

import { bytesToSize } from 'utils/dom'

import uploadService from 'services/uploads'

import Badge from 'components/ui/badge'
import Icon from 'components/ui/icon'
import Grid from 'components/ui/layout/grid'

import { FileWrapper, FilesWrapper } from './styles'

export type FilePayload = {
    '@id': string
    contentUrl?: string
    originalName?: string
    size?: number
}

interface FileProps {
    name: string
    endpoint: string
    placeholder?: string
    maxFile?: number
    maxSize?: number
    value?: string | FilePayload
    onChange?: (value: string | null) => void
}

const Files: React.FC<FileProps> = ({
    name,
    endpoint,
    placeholder = 'Cliquez pour télécharger',
    maxFile = 1,
    maxSize,
    value,
    onChange
}) => {
    const form = Form.useFormInstance()
    // TODO: Needs to simplify the code
    // Local state for UI
    const [fileList, setFileList] = useState<UploadFile<FilePayload>[]>([])

    const normalizeFileData = useCallback((fileValue: any): UploadFile<FilePayload> | null => {
        if (!fileValue) return null

        if (typeof fileValue === 'string') {
            return {
                uid: fileValue,
                name: 'File',
                status: 'done',
                url: fileValue,
                size: 0,
                response: { '@id': fileValue }
            }
        }

        if (typeof fileValue === 'object' && fileValue['@id']) {
            return {
                uid: fileValue['@id'],
                name: fileValue.originalName || 'File',
                status: 'done',
                url: fileValue.contentUrl || '',
                size: fileValue.size || 0,
                response: fileValue
            }
        }

        return null
    }, [])

    useEffect(() => {
        // Retrieve the field value (either directly from value or from the form)
        const fieldValue = value || form.getFieldValue(name)

        if (fieldValue && fileList.length === 0) {
            const fileData = normalizeFileData(fieldValue)
            if (fileData) {
                setFileList([fileData])
            }
        }
    }, [value, form, name, fileList.length, normalizeFileData])

    // Custom upload
    const handleCustomRequest = useCallback<UploadProps['customRequest']>(
        (options) => {
            const { file, onSuccess, onError } = options

            if (maxSize && typeof file !== 'string' && file.size > maxSize) {
                message.error('File too large')
                onError?.(new Error('File too large'))
                return
            }

            const formData = new FormData()
            formData.append('file', file as Blob)

            uploadService(endpoint, formData)
                .then((response) => {
                    const payload = response as FilePayload

                    // Store only the IRI in the form (hidden field)
                    form.setFieldsValue({ [name]: payload['@id'] })

                    // Update the UI with complete file info
                    setFileList([{
                        uid: payload['@id'],
                        name: (file as any).name || 'File',
                        status: 'done',
                        response: payload,
                        url: payload.contentUrl || '',
                        size: payload.size || (file as File).size
                    }])

                    // Notify the parent if necessary
                    if (onChange) onChange(payload['@id'])

                    onSuccess?.(response)
                })
                .catch((error) => {
                    message.error('Upload failed')
                    onError?.(error)
                })
        },
        [endpoint, maxSize, form, name, onChange]
    )

    // File change handling
    const handleChange = useCallback<UploadProps['onChange']>(
        (info) => {
            // Update fileList only for UI, not to change the form value
            if (info.file.status !== 'uploading') {
                const list = info.fileList.slice(-maxFile)
                setFileList(list)
            }
        },
        [maxFile]
    )

    // File removal
    const handleRemove = useCallback(() => {
        form.setFieldsValue({ [name]: undefined })
        setFileList([])
        if (onChange) onChange(null)

        return true
    }, [form, name, onChange])

    return (
        <FilesWrapper>
            <AntdUpload
                disabled={fileList.length >= maxFile}
                customRequest={handleCustomRequest}
                fileList={fileList}
                onChange={handleChange}
                onRemove={handleRemove}
                maxCount={maxFile}
                listType="picture-card"
            >
                {fileList.length < maxFile && (
                    <div>
                        <UploadOutlined style={{ fontSize: 24 }} />
                        <p>{placeholder}</p>
                    </div>
                )}
            </AntdUpload>

            {fileList.length > 0 && (
                <>
                    <Grid gap={3}>
                        {fileList.map((file, index) => (
                            <FileWrapper
                                status={file.status}
                                key={file.uid || index}
                                onClick={() => handleRemove()}
                            >
                                <Icon
                                    icon={UploadOutlined}
                                    isLoading={file.status === 'uploading'}
                                    size={20}
                                />
                                <span>
                                    {file.name} - {bytesToSize(file.size || 0)}
                                </span>
                                <MdClose className="close-icon" />
                            </FileWrapper>
                        ))}
                    </Grid>
                    <Badge className="files-badge">
                        {fileList.length} file{fileList.length > 1 ? 's' : ''},{' '}
                        {bytesToSize(fileList.reduce((acc, item) => acc + (item.size || 0), 0))}
                    </Badge>
                </>
            )}
            <Form.Item name={name} hidden>
                <Input />
            </Form.Item>
        </FilesWrapper>
    )
}

export default Files
