import React, { useState, useCallback, useEffect } from 'react'

import dynamic from 'next/dynamic'

import { css } from '@emotion/css'

import { RiDragDropLine } from '@react-icons/all-files/ri/RiDragDropLine'
import { Form, FormItemProps, Input, Upload, UploadProps } from 'antd'
import { useTranslation } from 'react-i18next'

import upload from 'services/uploads'

import { Label, Container } from './styles'

// Dynamic import to avoid hydration issues
const ImgCrop = dynamic(
    () => import('antd-img-crop').then(mod => mod.default),
    { ssr: false }
)

type ImageUploadProps = {
  name?: string
  label?: string
  endpoint?: string
  // Optional: you can directly pass a fileList if already formatted
  defaultValues?: UploadProps['fileList']
  ratio?: number
  height?: number
  width?: number
  rules?: FormItemProps['rules']
}

const Image = ({
    name = 'image',
    label,
    endpoint,
    defaultValues,
    ratio = 16 / 9,
    height,
    width,
    rules
}: ImageUploadProps): JSX.Element => {
    const { t } = useTranslation()
    const form = Form.useFormInstance()

    // Internal state for fileList
    const [fileList, setFileList] = useState<UploadProps['fileList']>(
        defaultValues || []
    )

    // Synchronization effect: if defaultValues change, use them
    useEffect(() => {
        if (defaultValues) {
            setFileList(defaultValues)
        } else {
            // If no value is passed as a prop, check the field value in the form
            const fieldValue = form.getFieldValue(name)
            if (fieldValue && typeof fieldValue === 'object' && fieldValue['@id']) {
                setFileList([
                    {
                        uid: fieldValue.id || fieldValue['@id'],
                        name: fieldValue.originalName || 'image',
                        status: 'done',
                        url: fieldValue.contentUrl,
                        response: fieldValue
                    }
                ])
            } else {
                setFileList([])
            }
        }
    }, [defaultValues, name, form])

    const onChange = useCallback<UploadProps['onChange']>(
        ({ fileList: newFileList }) => {
            setFileList(newFileList)
        },
        []
    )

    const onPreview = useCallback<UploadProps['onPreview']>(async (file) => {
        let src = file.url as string
        if (!src) {
            src = await new Promise<string>((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj as Blob)
                reader.onload = () => resolve(reader.result as string)
            })
        }
        const image = new window.Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }, [])

    const uploadImage = useCallback<UploadProps['customRequest']>(
        ({ file, onSuccess, onError }) => {
            const data = new FormData()
            data.append('file', file as Blob)

            upload(endpoint, data)
                .then((response) => {
                    // Store the @id returned by the API in the form
                    form.setFieldsValue({ [name]: response?.['@id'] })
                    onSuccess?.(response)
                })
                .catch((err) => {
                    onError?.(err)
                })
        },
        [endpoint, form, name]
    )

    // Conditional class for the container (if file present or not)
    const containerClass = fileList && fileList.length > 0 ? 'hasFile' : 'noFile'

    return (
        <Container $width={width} $height={height} className={containerClass}>
            {label && <Label>{label}</Label>}
            {typeof window !== 'undefined' && (
                <ImgCrop
                    aspect={ratio}
                    rotationSlider
                    modalTitle={t('form.input.image.modal.title')}
                    modalProps={{
                        maskProps: {
                            'aria-hidden': 'false',
                            tabIndex: -1
                        }
                    } as any}
                >
                    <Upload
                        maxCount={1}
                        customRequest={uploadImage}
                        listType="picture-card"
                        accept="image/*"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        onRemove={() => {
                            form.setFieldsValue({ [name]: null })
                        }}
                    >
                        {fileList && fileList.length < 1 && (
                            <div
                                className={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    height: 100%;
                                    `}
                            >
                                <RiDragDropLine size={24} />
                                <div className={css`margin-top: 8px`}>
                                    {t('input.upload.image.placeholder')}
                                </div>
                            </div>
                        )}
                    </Upload>
                </ImgCrop>
            )}
            <Form.Item name={name} rules={rules} hidden>
                <Input />
            </Form.Item>
        </Container>
    )
}

export default Image
