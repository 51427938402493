import React from 'react'

import { Form, Input, Select } from 'antd'

import { InputProps } from '../input'

import { Container } from './styles'

const Text = ({
    name,
    label,
    rules,
    required,
    col,
    hidden,
    rows,
    component = 'input',
    options,
    readOnly,
    defaultValue,
    type = 'text',
    ...props
}: InputProps): JSX.Element => {
    const Wrapper = options ? Input.Group : React.Fragment
    const wrapperProps = options ? { compact: true } : {}

    const inputProps = {
        disabled: readOnly,
        type
    }

    return (
        <Container>
            <Form.Item
                hidden={hidden}
                label={label}
                rules={rules}
                required={required}
                wrapperCol={col}
                {...props}
            >
                <Wrapper {...wrapperProps}>
                    {options && (
                        <Select
                            defaultValue={options[0].value}
                        >
                            {options.map(option => (
                                <Select.Option
                                    value={option.value}
                                    key={option.value}
                                >
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                    <Form.Item name={name} noStyle initialValue={defaultValue}>
                        {component === 'input' ? <Input {...inputProps} /> : <Input.TextArea maxLength={rows} {...inputProps} />}
                    </Form.Item>
                </Wrapper>
            </Form.Item>
        </Container>
    )
}
export default Text
